@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap);
* { margin: 0; padding: 0; }

:root {
    --max-width: 1240px;

    --url-color: #EC635D;
    --text-color: #3a3a3a;
    --bg-color-main: #F8F8F8;
    --bg-color-secondary: #FFFFFF;

    --main-heading-text-size: 50px;
    --heading-text-size: 40px;
    --subheading-text-size: 26px;
    --section-text-size: 20px;

    --strong-text-size: 16px;
    --main-text-size: 14px;

    --main-padding: 30px;
    --half-padding: 15px;

    --border-radius-controls: 10px;
    --buttons-text-size: 14px;
    --inactive-color: #cccccc;

}

body {
    background-color: #F8F8F8;
    background-color: var(--bg-color-main);
    font-family: 'Open Sans', SansSerif, serif;
    font-weight: 400;
    color: #3a3a3a;
    color: var(--text-color);
    line-height: 1.4;
    font-size: 14px;
    font-size: var(--main-text-size);
}

h1, h2, h3, h4 {
    font-family: 'Montserrat', 'Open Sans', SansSerif, serif;
    font-weight: 700;
}

h1 {
    font-size: 50px;
    font-size: var(--main-heading-text-size);
}

h2 {
    font-size: 40px;
    font-size: var(--heading-text-size);
}

h3 {
    font-size: 26px;
    font-size: var(--subheading-text-size);
}

h4 {
    font-size: 20px;
    font-size: var(--section-text-size);
}

h5 {
    font-size: 16px;
    font-size: var(--strong-text-size);
}

p {
    font-size: 14px;
    font-size: var(--main-text-size);
}

a {
    color: #EC635D;
    color: var(--url-color);
}

div, p { /* Safari/Chrome, other WebKit */    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
}

.separator {
    height: 10px;
    border-bottom: #e0e0e0 1px solid;
}

.displayMobile {
    display: none;
}

.displayWeb {
    display: block;
}

.cursorDefault {
    cursor: default;
}

.cursorPointer {
    cursor: default;
}


/*
/ MAIN LAYOUT
 */

.wrapper {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.container {
    width: 100%;
    max-width: 1240px;
    max-width: var(--max-width);
    padding: 0 30px;
    padding: 0 var(--main-padding);
}

.tile {
    padding: 30px;
    padding: var(--main-padding);
    width: 40%;
    min-width: 280px;
    border: #eeeeee 1px solid;
    box-shadow: 0 0 10px rgba(0,0,0,0.05);
    margin-right: 50px;
    margin-bottom: 50px;
}


/*
/ Media Queries
 */

@media (min-width: 0px) and (max-width:980px) {

}

@media (min-width: 450px) and (max-width:600px) {
    :root {
        --main-heading-text-size: 40px;
        --heading-text-size: 30px;
        --section-text-size: 18px;
        --subheading-text-size: 20px;
        --main-padding: 20px;
    }
}

@media (min-width: 0px) and (max-width:450px) {
    :root {
        --main-heading-text-size: 40px;
        --heading-text-size: 30px;
        --section-text-size: 16px;
        --subheading-text-size: 15px;
        --main-text-size: 14px;
        --main-padding: 20px;
        --half-padding: 10px;
    }

    .displayMobile {
        display: block;
    }

    .displayWeb {
        display: none;
    }

}

@media (min-width: 0px) and (max-width:330px) {
    :root {
        --logo-text-size: 30px;
        --heading-text-size: 20px;
        --subheading-text-size: 15px;
        --main-text-size: 13px;
        --main-padding: 15px;
        --half-padding: 8px;
    }

}

/* margin ----------- */
.no-m {margin: 0}
.no-mt {margin-top: 0}
.no-mb {margin-bottom: 0}
.no-mr {margin-right: 0}
.no-ml {margin-left: 0}

.m-0 {margin: 0}
.m-10 {margin: 10px}
.m-15 {margin: 15px}
.m-20 {margin: 20px}
.m-30 {margin: 30px}

/* margin-top */
.mt-5 {margin-top: 5px}
.mt-10 {margin-top: 10px}
.mt-15 {margin-top: 15px}
.mt-20 {margin-top: 20px}
.mt-30 {margin-top: 30px}
.mt-40 {margin-top: 40px}
.mt-50 {margin-top: 50px}

/* margin-bottom */
.mb-5 {margin-bottom: 5px}
.mb-10 {margin-bottom: 10px}
.mb-15 {margin-bottom: 15px}
.mb-20 {margin-bottom: 20px}
.mb-30 {margin-bottom: 30px}
.mb-40 {margin-bottom: 40px}
.mb-50 {margin-bottom: 50px}

/* margin-left */
.ml-5 {margin-left: 5px}
.ml-10 {margin-left: 10px}
.ml-15 {margin-left: 15px}
.ml-20 {margin-left: 20px}
.ml-25 {margin-left: 25px}
.ml-30 {margin-left: 30px}

/* margin-right */
.mr-5 {margin-right: 5px}
.mr-10 {margin-right: 10px}
.mr-15 {margin-right: 15px}
.mr-20 {margin-right: 20px}
.mr-25 {margin-right: 25px}
.mr-30 {margin-right: 30px}

/* padding ----------- */

.p-0 {padding: 0}
.p-5 {padding: 5px}
.p-10 {padding: 10px}
.p-15 {padding: 15px}
.p-20 {padding: 20px}
.p-30 {padding: 30px}

/* padding-top */
.pt-5 {padding-top: 5px}
.pt-10 {padding-top: 10px}
.pt-15 {padding-top: 15px}
.pt-20 {padding-top: 20px}
.pt-25 {padding-top: 25px}
.pt-30 {padding-top: 30px}

/* padding-bottom */
.pb-5 {padding-bottom: 5px}
.pb-10 {padding-bottom: 10px}
.pb-15 {padding-bottom: 15px}
.pb-20 {padding-bottom: 20px}
.pb-25 {padding-bottom: 25px}
.pb-30 {padding-bottom: 30px}

/* padding-left */
.pl-5 {padding-left: 5px}
.pl-10 {padding-left: 10px}
.pl-15 {padding-left: 15px}
.pl-20 {padding-left: 20px}
.pl-25 {padding-left: 25px}
.pl-30 {padding-left: 30px}
.pl-80 {padding-left: 80px}


/* padding-right */
.pr-5 {padding-right: 5px}
.pr-10 {padding-right: 10px}
.pr-15 {padding-right: 15px}
.pr-20 {padding-right: 20px}
.pr-25 {padding-right: 25px}
.pr-30 {padding-right: 30px}

/* width ----------- */

.w-10 {width: 10px}
.w-20 {width: 20px}
.w-25 {width: 25px}
.w-30 {width: 30px}
.w-40 {width: 40px}
.w-50 {width: 50px}
.w-75 {width: 75px}
.w-100 {width: 100px}
.w-125 {width: 125px}
.w-150 {width: 150px}
.w-175 {width: 175px}
.w-200 {width: 200px}
.w-225 {width: 225px}
.w-250 {width: 250px}
.w-275 {width: 275px}
.w-300 {width: 300px}
.w-350 {width: 350px}
.w-400 {width: 400px}
.w-450 {width: 450px}
.w-500 {width: 500px}
.w-550 {width: 550px}
.w-600 {width: 600px}

.h-10 {height: 10px}
.h-20 {height: 20px}
.h-30 {height: 30px}
.h-40 {height: 40px}
.h-50 {height: 50px}
.h-75 {height: 75px}
.h-100 {height: 100px}
.h-150 {height: 150px}
.h-200 {height: 200px}

.hp-100 {height: 100%}

.wp-30 {width: 30%}
.wp-33 {width: 33%}
.wp-50 {width: 50%}
.wp-85 {width: 85%}
.wp-95 {width: 95%}
.wp-100 {width: 100%}

.mw-25 {min-width: 25px}
.mw-50 {min-width: 50px}
.mw-75 {min-width: 75px}
.mw-100 {min-width: 100px}
.mw-125 {min-width: 125px}
.mw-150 {min-width: 150px}
.mw-200 {min-width: 200px}
.mw-250 {min-width: 250px}
.mw-300 {min-width: 300px}
.mw-350 {min-width: 350px}
.mw-400 {min-width: 400px}

.mxw-25 {max-width: 25px}
.mxw-50 {max-width: 50px}
.mxw-75 {max-width: 75px}
.mxw-100 {max-width: 100px}
.mxw-125 {max-width: 125px}
.mxw-150 {max-width: 150px}
.mxw-200 {max-width: 200px}
.mxw-250 {max-width: 250px}
.mxw-300 {max-width: 300px}
.mxw-350 {max-width: 350px}
.mxw-370 {max-width: 370px}
.mxw-400 {max-width: 400px}
.mxw-500 {max-width: 500px}

/* other ----------- */

.d-none {display: none}
.d-block {display: block}
.d-flex {display: -webkit-flex;display: flex}
.d-flex-wrap {display: -webkit-flex;display: flex; -webkit-flex-wrap: wrap; flex-wrap: wrap}
.d-flex-wrap-sb {display: -webkit-flex;display: flex; -webkit-flex-wrap: wrap; flex-wrap: wrap; -webkit-justify-content: space-between; justify-content: space-between;}
.d-flex-wrap-ac {display: -webkit-flex;display: flex; -webkit-flex-wrap: wrap; flex-wrap: wrap; -webkit-align-items: center; align-items: center}
.d-flex-wrap-sb-ac {display: -webkit-flex;display: flex; -webkit-justify-content: space-between; justify-content: space-between; -webkit-align-items: center; align-items: center; -webkit-flex-wrap: wrap; flex-wrap: wrap}
.d-flex-ac {display: -webkit-flex;display: flex; -webkit-align-items: center; align-items: center}
.d-flex-sb {display: -webkit-flex;display: flex; -webkit-justify-content: space-between; justify-content: space-between;}
.d-flex-end {display: -webkit-flex;display: flex; -webkit-justify-content: flex-end; justify-content: flex-end;}
.d-flex-center {display: -webkit-flex;display: flex; -webkit-justify-content: center; justify-content: center; -webkit-align-items: center; align-items: center}

.ta-left {text-align: left}
.ta-center {text-align: center}
.ta-right {text-align: right}

.fs-10 {font-size: 10px}
.fs-12 {font-size: 12px}
.fs-14 {font-size: 14px}

.fs-10-i {font-size: 10px; font-style: italic;}
.fs-12-i {font-size: 12px; font-style: italic;}
.fs-14-i {font-size: 14px; font-style: italic;}


.yellow { background-color: #FFEDAC; }

.blue { background-color: #039BE5; color: #FFFFFF; }

.red { background-color: #EC635D; color: #FFFFFF; }

.black { background-color: #000000; color: #FFFFFF; }

.green { background-color: #E9FFAC;}

.gray { background-color: #f0f0f0;}

.white { background-color: #ffffff; }

/* mobile/web ----------- */

.mrweb-10 {margin-right: 10px}
.mrweb-15 {margin-right: 15px}
.mrweb-20 {margin-right: 20px}
.mrweb-25 {margin-right: 25px}
.mrweb-30 {margin-right: 30px}

.mlweb-10 {margin-left: 10px}
.mlweb-15 {margin-left: 15px}
.mlweb-20 {margin-left: 20px}
.mlweb-25 {margin-left: 25px}
.mlweb-30 {margin-left: 30px}

.mbweb-10 {margin-bottom: 0}
.mbweb-15 {margin-bottom: 0}
.mbweb-20 {margin-bottom: 0}
.mbweb-25 {margin-bottom: 0}
.mbweb-30 {margin-bottom: 0}

.wrapMobile {-webkit-flex-wrap: nowrap;flex-wrap: nowrap}

@media (min-width: 0px) and (max-width:450px) {
    .mrweb-10 {margin-right: 0}
    .mrweb-15 {margin-right: 0}
    .mrweb-20 {margin-right: 0}
    .mrweb-25 {margin-right: 0}
    .mrweb-30 {margin-right: 0}

    .mlweb-10 {margin-left: 0}
    .mlweb-15 {margin-left: 0}
    .mlweb-20 {margin-left: 0}
    .mlweb-25 {margin-left: 0}
    .mlweb-30 {margin-left: 0}

    .mbweb-10 {margin-bottom: 10px}
    .mbweb-15 {margin-bottom: 15px}
    .mbweb-20 {margin-bottom: 20px}
    .mbweb-25 {margin-bottom: 25px}
    .mbweb-30 {margin-bottom: 30px}

    .wmobile {width: 100%}

    .wrapMobile {-webkit-flex-wrap: wrap;flex-wrap: wrap}
}

/*
/ FORMS
 */
:root {
    --url1: #32B9F1;
    --url2: #6822B0;
    --url3: #3D89AE;
    --text-color: #1A1A1A;
    --secondary-color: #3D89AE;
    --note-color: #b0bbc1;
    --bg-color-main: #ffffff;
    --bg-color-secondary: #F7F7F7;
    --bg-color-third: #E6EDF6;
}

input, select, textarea, button, .button-alt, .button-blue  {
    height: 40px;
    box-shadow: 0 0 10px rgba(0,0,0,0.05);
    -ms-box-sizing:border-box;
    box-sizing:border-box;
    padding : 5px 15px;
    border-radius: 4px;
    border: 1px solid #E6EDF6;
    border: 1px solid var(--bg-color-third);
    outline: none;
}

input {
    width: 150px;
}

button {
    background-color: #32B9F1;
    background-color: var(--url1);
    color: #ffffff;
    color: var(--bg-color-main);
    cursor: pointer;
}

.button-blue {
    background-color: #3D89AE;
    background-color: var(--url3);
    color: #ffffff;
    color: var(--bg-color-main);
    cursor: pointer;
}

.button-alt {
    background-color: #ffffff;
    background-color: var(--bg-color-main);
    color: #3D89AE;
    color: var(--url3);
    border: 1px solid #3D89AE;
    border: 1px solid var(--url3);
    cursor: pointer;
}

.button-link {
    height: 25px;
    color: #32B9F1;
    color: var(--url1);
    text-decoration: underline;
    box-sizing:content-box;
    padding : 10px 15px;
    border-radius: 4px;
    border: 0 solid #ffffff;
    border: 0 solid var(--bg-color-main);
    cursor: pointer;
    background-color: unset;
    outline: none;

}

.button-ico {
    outline: none;
    margin-right: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 0;
    background-color: #ffffff;
    background-color: var(--bg-color-main);
    padding-top: 5px;
    cursor: pointer;

}

.button-alt:disabled {
    cursor: auto;
    opacity: 0.3;
}

select {
    background: url(data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E) no-repeat;
    -webkit-appearance: none;
    appearance: none;
    background-size: .65em;
    background-position: calc(100% - 1em) center;
}

select::-ms-expand {
    display: none;
}

input:focus, select:focus, textarea:focus {
    outline: none;
    border: 1px solid #3D89AE;
    border: 1px solid var(--url3);
}

textarea {
    resize: none;
    height: 100px;
}

.btn-diabled {
    pointer-events: none;
}


.custom-check span {
    font-size: 24px;
}

.custom-check input {
    width: 20px;
    height: 20px;
}

/*
/ Checkboxes
 */

.checkbox-container {
    display:-webkit-inline-flex;
    display:inline-flex;
    margin-right: 10px;
    width: 32px;
    height: 20px;
    padding: 3px;
    border: #3D89AE 1px solid;
    border: var(--url3) 1px solid;
    border-radius: 16px;
    cursor: pointer;
}

.checkbox-checked {
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    background-color: #3D89AE;
    background-color: var(--url3);
    border: #3D89AE 1px solid;
    border: var(--url3) 1px solid;
}

.checkbox-slider {
    width: 12px;
    height: 12px;
    border-radius: 12px;
    border: #3D89AE 1px solid;
    border: var(--url3) 1px solid;

}

.checkbox-slider-checked {
    width: 12px;
    height: 12px;
    border-radius: 12px;
    background-color: #ffffff;
    border: #3D89AE 1px solid;
    border: var(--url3) 1px solid;

}


/*
/ MEDIA
 */


