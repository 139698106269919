/* margin ----------- */
.no-m {margin: 0}
.no-mt {margin-top: 0}
.no-mb {margin-bottom: 0}
.no-mr {margin-right: 0}
.no-ml {margin-left: 0}

.m-0 {margin: 0}
.m-10 {margin: 10px}
.m-15 {margin: 15px}
.m-20 {margin: 20px}
.m-30 {margin: 30px}

/* margin-top */
.mt-5 {margin-top: 5px}
.mt-10 {margin-top: 10px}
.mt-15 {margin-top: 15px}
.mt-20 {margin-top: 20px}
.mt-30 {margin-top: 30px}
.mt-40 {margin-top: 40px}
.mt-50 {margin-top: 50px}

/* margin-bottom */
.mb-5 {margin-bottom: 5px}
.mb-10 {margin-bottom: 10px}
.mb-15 {margin-bottom: 15px}
.mb-20 {margin-bottom: 20px}
.mb-30 {margin-bottom: 30px}
.mb-40 {margin-bottom: 40px}
.mb-50 {margin-bottom: 50px}

/* margin-left */
.ml-5 {margin-left: 5px}
.ml-10 {margin-left: 10px}
.ml-15 {margin-left: 15px}
.ml-20 {margin-left: 20px}
.ml-25 {margin-left: 25px}
.ml-30 {margin-left: 30px}

/* margin-right */
.mr-5 {margin-right: 5px}
.mr-10 {margin-right: 10px}
.mr-15 {margin-right: 15px}
.mr-20 {margin-right: 20px}
.mr-25 {margin-right: 25px}
.mr-30 {margin-right: 30px}

/* padding ----------- */

.p-0 {padding: 0}
.p-5 {padding: 5px}
.p-10 {padding: 10px}
.p-15 {padding: 15px}
.p-20 {padding: 20px}
.p-30 {padding: 30px}

/* padding-top */
.pt-5 {padding-top: 5px}
.pt-10 {padding-top: 10px}
.pt-15 {padding-top: 15px}
.pt-20 {padding-top: 20px}
.pt-25 {padding-top: 25px}
.pt-30 {padding-top: 30px}

/* padding-bottom */
.pb-5 {padding-bottom: 5px}
.pb-10 {padding-bottom: 10px}
.pb-15 {padding-bottom: 15px}
.pb-20 {padding-bottom: 20px}
.pb-25 {padding-bottom: 25px}
.pb-30 {padding-bottom: 30px}

/* padding-left */
.pl-5 {padding-left: 5px}
.pl-10 {padding-left: 10px}
.pl-15 {padding-left: 15px}
.pl-20 {padding-left: 20px}
.pl-25 {padding-left: 25px}
.pl-30 {padding-left: 30px}
.pl-80 {padding-left: 80px}


/* padding-right */
.pr-5 {padding-right: 5px}
.pr-10 {padding-right: 10px}
.pr-15 {padding-right: 15px}
.pr-20 {padding-right: 20px}
.pr-25 {padding-right: 25px}
.pr-30 {padding-right: 30px}

/* width ----------- */

.w-10 {width: 10px}
.w-20 {width: 20px}
.w-25 {width: 25px}
.w-30 {width: 30px}
.w-40 {width: 40px}
.w-50 {width: 50px}
.w-75 {width: 75px}
.w-100 {width: 100px}
.w-125 {width: 125px}
.w-150 {width: 150px}
.w-175 {width: 175px}
.w-200 {width: 200px}
.w-225 {width: 225px}
.w-250 {width: 250px}
.w-275 {width: 275px}
.w-300 {width: 300px}
.w-350 {width: 350px}
.w-400 {width: 400px}
.w-450 {width: 450px}
.w-500 {width: 500px}
.w-550 {width: 550px}
.w-600 {width: 600px}

.h-10 {height: 10px}
.h-20 {height: 20px}
.h-30 {height: 30px}
.h-40 {height: 40px}
.h-50 {height: 50px}
.h-75 {height: 75px}
.h-100 {height: 100px}
.h-150 {height: 150px}
.h-200 {height: 200px}

.hp-100 {height: 100%}

.wp-30 {width: 30%}
.wp-33 {width: 33%}
.wp-50 {width: 50%}
.wp-85 {width: 85%}
.wp-95 {width: 95%}
.wp-100 {width: 100%}

.mw-25 {min-width: 25px}
.mw-50 {min-width: 50px}
.mw-75 {min-width: 75px}
.mw-100 {min-width: 100px}
.mw-125 {min-width: 125px}
.mw-150 {min-width: 150px}
.mw-200 {min-width: 200px}
.mw-250 {min-width: 250px}
.mw-300 {min-width: 300px}
.mw-350 {min-width: 350px}
.mw-400 {min-width: 400px}

.mxw-25 {max-width: 25px}
.mxw-50 {max-width: 50px}
.mxw-75 {max-width: 75px}
.mxw-100 {max-width: 100px}
.mxw-125 {max-width: 125px}
.mxw-150 {max-width: 150px}
.mxw-200 {max-width: 200px}
.mxw-250 {max-width: 250px}
.mxw-300 {max-width: 300px}
.mxw-350 {max-width: 350px}
.mxw-370 {max-width: 370px}
.mxw-400 {max-width: 400px}
.mxw-500 {max-width: 500px}

/* other ----------- */

.d-none {display: none}
.d-block {display: block}
.d-flex {display: flex}
.d-flex-wrap {display: flex; flex-wrap: wrap}
.d-flex-wrap-sb {display: flex; flex-wrap: wrap; justify-content: space-between;}
.d-flex-wrap-ac {display: flex; flex-wrap: wrap; align-items: center}
.d-flex-wrap-sb-ac {display: flex; justify-content: space-between; align-items: center; flex-wrap: wrap}
.d-flex-ac {display: flex; align-items: center}
.d-flex-sb {display: flex; justify-content: space-between;}
.d-flex-end {display: flex; justify-content: flex-end;}
.d-flex-center {display: flex; justify-content: center; align-items: center}

.ta-left {text-align: left}
.ta-center {text-align: center}
.ta-right {text-align: right}

.fs-10 {font-size: 10px}
.fs-12 {font-size: 12px}
.fs-14 {font-size: 14px}

.fs-10-i {font-size: 10px; font-style: italic;}
.fs-12-i {font-size: 12px; font-style: italic;}
.fs-14-i {font-size: 14px; font-style: italic;}


.yellow { background-color: #FFEDAC; }

.blue { background-color: #039BE5; color: #FFFFFF; }

.red { background-color: #EC635D; color: #FFFFFF; }

.black { background-color: #000000; color: #FFFFFF; }

.green { background-color: #E9FFAC;}

.gray { background-color: #f0f0f0;}

.white { background-color: #ffffff; }

/* mobile/web ----------- */

.mrweb-10 {margin-right: 10px}
.mrweb-15 {margin-right: 15px}
.mrweb-20 {margin-right: 20px}
.mrweb-25 {margin-right: 25px}
.mrweb-30 {margin-right: 30px}

.mlweb-10 {margin-left: 10px}
.mlweb-15 {margin-left: 15px}
.mlweb-20 {margin-left: 20px}
.mlweb-25 {margin-left: 25px}
.mlweb-30 {margin-left: 30px}

.mbweb-10 {margin-bottom: 0}
.mbweb-15 {margin-bottom: 0}
.mbweb-20 {margin-bottom: 0}
.mbweb-25 {margin-bottom: 0}
.mbweb-30 {margin-bottom: 0}

.wrapMobile {flex-wrap: nowrap}

@media (min-width: 0px) and (max-width:450px) {
    .mrweb-10 {margin-right: 0}
    .mrweb-15 {margin-right: 0}
    .mrweb-20 {margin-right: 0}
    .mrweb-25 {margin-right: 0}
    .mrweb-30 {margin-right: 0}

    .mlweb-10 {margin-left: 0}
    .mlweb-15 {margin-left: 0}
    .mlweb-20 {margin-left: 0}
    .mlweb-25 {margin-left: 0}
    .mlweb-30 {margin-left: 0}

    .mbweb-10 {margin-bottom: 10px}
    .mbweb-15 {margin-bottom: 15px}
    .mbweb-20 {margin-bottom: 20px}
    .mbweb-25 {margin-bottom: 25px}
    .mbweb-30 {margin-bottom: 30px}

    .wmobile {width: 100%}

    .wrapMobile {flex-wrap: wrap}
}
